/* eslint-disable no-mixed-spaces-and-tabs */
import { isProduction } from '../../helpers/environment'
import { displayAds, getAdsData, adsUtils } from '@financial-times/ads-display'
import displayNativeAds from './native-ads'

// If we use `appContext.get('appName')` the value retured will be "home-page",
// but the value required by the ads backend must start with "frontpage"
const APP_NAME = 'frontpage-beta'

async function init(flags, appContext) {
	const rootId = adsUtils.getRootID()
	const displayAdsOptions = {
		appName: APP_NAME,
		abTestState: appContext.get('abTestState'),
		rootId,
		sandbox: !isProduction,
		lazyLoadMargins: {
			760: '15%',
			980: '5%',
		},
		formats: {
			HighImpactSlot: { sizes: [970, 400] },
		},
	}

	let adsData
	try {
		adsData = await getAdsData({
			user: true,
		})

		await displayAds.init(
			{
				...displayAdsOptions,
				targeting: adsData.metadata,
				adUnit: ['home', appContext.get('edition') === 'uk' ? 'UK' : 'US', 'hprb'],
			},
			flags,
		)

		if (flags.get('AdsPermutive')) {
			adsUtils.enablePermutiveFtCom({
				metadata: adsData.metadata,
				type: APP_NAME,
				rootId,
			})
		}
	} catch (e) {
		if (!adsData) {
			console.warn(
				'There was an error fetching the ads data. Loading basic permutive and ads without targeting or ad unit',
				e,
			)

			displayAds.init(displayAdsOptions, flags)
			adsUtils.enablePermutiveFtCom({
				type: APP_NAME,
				rootId,
			})
		}
	}
}

export default {
	init,
	displayNativeAds,
	updateConsentInfo: adsUtils.updateConsentInfo,
}
